import React, { useContext, useState } from "react"
import UserContext from "../../../../../structure/Context/UserContext";
import { Authenticate } from "../../../../../../services/UserService";
import { navigate } from 'gatsby';

import "./styles.scss";

const Connection: React.FunctionComponent<{
  onRegisterPage: boolean;
  classElt?: string;
  dataTheme?: string;
  setLpStep: any;
}> = ({ onRegisterPage, classElt, dataTheme, setLpStep }) => {
  const { setUserContext } = useContext(UserContext);

  const [authData, setAuthData] = useState({
    login: '',
    password: '',
    valid: false
  });

  const [error, setError] = useState({
    login: '',
    password: '',
    server: ''
  })

  /**
   * Check a password between 8 to 15 characters which contain at least 
   * - one lowercase letter, 
   * - one uppercase letter, 
   * - one numeric digit
   * - one special character
   * 
   * @param password 
   */
  const isPasswordValid = (password: string): boolean => {
    const pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    return !!password.match(pattern);
  }

  const onChangeData = (type: string, value: string): void => {
    const err = { ...error };
    err.server = '';

    switch (type) {
      case 'login':
        err.login = '';
        if ('' === value) {
          err.login = 'Veuillez renseigner un email';
        } else if (!value.match(/^[\w.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+$/)) {
          err.login = 'Votre email est invalide';
        }
        break;
      case 'password':
        err.password = '';
        if ('' === value) {
          err.password = 'Veuillez renseigner un mot de passe';
        } else if (!isPasswordValid(value)) {
          err.password = 'Votre mot de passe est invalide.';
        }
        break;
    }

    const isValid = ('' === err.login && '' === err.password);
    setError(err);
    setAuthData({ ...authData, ...{ [type]: value, valid: isValid } });
  }

  const authentication = async (e: any): Promise<void> => {
    e.preventDefault();
    Authenticate({ login: authData.login, password: authData.password })
      .then((response: any) => {
        if (!!response.data.accessToken) {
          setUserContext({ auth: { token: response.data.accessToken, r: response.data.r } })
          if(!!onRegisterPage){
            navigate('/annuaire');
          }else{
            document.location.reload();
          }
        } else {
          setError({ ...error, ...{ server: 'La combinaison email/mot de passe renseignée est incorrecte.' } });
        }
      }, err => {
        setError({ ...error, ...{ server: 'La combinaison email/mot de passe renseignée est incorrecte.' } });
        // console.log(err)
      });
  }

  return (

    <section className={`section-main section-card section-connexion ${!!onRegisterPage ? null : 'section-signin'}`}>
      <div className={`section_inner ${classElt}`} data-theme={dataTheme}>
        <div className="section-title">
          <h2><b>J'ai {!!onRegisterPage ? 'déjà' : ''} un compte</b></h2>
        </div>
        <div className="section-content">
          <div className="action-lost-password">
            <button className="link link-primary" onClick={() => setLpStep('lost')}>
              <span className="link_label">Mot de passe oublié</span>
            </button>
          </div>


          <form className="form form-login" noValidate onSubmit={authentication}>
            <div className="form_inner">
              <div className="form-row row row-block">
                <label><span>Login</span></label>
                <input type="text" name="login" value={authData.login} pattern="" placeholder="Email" onChange={(e) => onChangeData('login', e.currentTarget.value)} />
                {!!error.login &&
                  <div className="form-error">
                    {error.login}
                  </div>
                }
              </div>
              <div className="form-row row row-block">
                <label>Mot de passe</label>
                <input type="password" name="password" value={authData.password} pattern="" placeholder="Mot de passe" onChange={(e) => onChangeData('password', e.currentTarget.value)} autoComplete="current-password"/>
                {!!error.password &&
                  <div className="form-error">
                    {error.password}
                  </div>
                }
              </div>
              {!!error.server &&
                <div className="form-error">
                  {error.server}
                </div>
              }
              <div className="form-row row row-block row-submit">
                <button className="btn btn-cta btn-secondary rounded" disabled={!authData.valid}>
                  <span className="btn_label">Se connecter</span>
                  <span className="btn_icon">
                    <i className="fas fa-arrow-right"></i>
                  </span>
                </button>
              </div>
            </div>
          </form>


        </div>
      </div>
    </section>
  );
}

export default Connection;