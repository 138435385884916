import React, { useContext, useEffect, useRef, useState } from "react"
import UserContext from "../../../../../structure/Context/UserContext";
import UploadChunk from '../../../../../../utils/UploadChunk';
import { navigate } from 'gatsby';

import Seo from "../../../../../structure/Seo/Seo";
import Split from "../../../../../structure/Split/Split";
import Connection from "../../../User/Modules/Connection/Connection";
import LostPassword from "../../../User/Modules/LostPassword/LostPassword";
import CitySkyline from "../../../../../element/Svg/CitySkyline";

import "./styles.scss";
import "../../../../../../../static/assets/css/react-tag.css";

import { Register } from "../../../../../../services/UserService";
import { isLoggedIn } from "../../../../../../services/AuthService";
import BlockInfo from "../../../../../element/Editor/Blocks/BlockInfo/BlockInfo";

import ReactTags from 'react-tag-autocomplete';

const RegisterPage = () => {

  const [lpStep, setLpStep] = useState<string>('empty');

  const { setUserContext } = useContext(UserContext);

  const refTags: any = useRef<any>(null);

  const [uploading, setUploading] = useState(false);

  const [confirmation, setConfirmation] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const [registerData, setRegisterData] = useState({
    email: '',
    lastname: '',
    firstname: '',
    employerNames: [],
    rulesAgreement: '',
    pressCard: [],
    valid: false
  });

  const [error, setError] = useState({
    email: '',
    lastname: '',
    firstname: '',
    employerNames: '',
    rulesAgreement: '',
    pressCard: [],
  });

  const onChangeData = (type: string, value: any, deleteData?: boolean): void => {
    const err = { ...error };
    setErrorMessage('');

    switch (type) {
      case 'email':
        err.email = '';
        if ('' === value) {
          err.email = 'Veuillez renseigner un email';
        } else if (!value.match(/^[\w.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+$/)) {
          err.email = 'Votre email est invalide';
        }
        break;
      case 'lastname':
      case 'firstname':
        err[type] = '';
        if ('' === value) {
          err[type] = 'Veuillez renseigner votre ' + ('lastname' === type ? 'nom' : 'prénom');
        } else if (!value.match(/^[\.a-zA-Z\-\séèëêçîïìüûùàâäöôÉÈËÊÇÎÏÌÜÛÙÀÂÄÖÔ\u0027]+$/)) {
          err[type] = 'Votre ' + ('lastname' === type ? 'nom' : 'prénom') + ' est invalide';
        }
        break;
      // case 'employerNames':
      //   err[type] = '';
      //   if ('' === value) {
      //     err[type] = 'Veuillez renseigner le nom de votre/vos employeur(s)';
      //   }
      //   break;
      case 'rulesAgreement':
        value = (value.checked) ? 1 : '';
        break;
      default:
    }

    let data: any = { ...registerData };
    if ('employerNames' === type) {
      err[type] = '';
      if (!!deleteData) {
        data.employerNames.splice(value, 1);
      } else {
        if (0 === data.employerNames.length || (0 < data.employerNames.length && 0 === data.employerNames.filter((en: any) => en.name === value.name).length)) {
          data.employerNames.push(value);
        }
      }
    } else {
      data[type] = value
      // const data = { ...registerData, ...{ [type]: value } };
    }

    err.pressCard = err.pressCard.filter((pc: string) => !!pc);

    data.valid = ('' === err.email && '' === err.lastname && '' === err.firstname 
    && 0 === err.pressCard.length 
    &&
      !!data.email && !!data.lastname && !!data.firstname && 0 < data.employerNames.length && !!data.rulesAgreement &&
      1 < data.pressCard.length);
    setError(err);
    setRegisterData(data);
  }

  const onHandleInputBlur = () => {
    if(0 === refTags.current.props.tags.length && !!refTags.current.state.query){
      setError({...error, ...{employerNames: 'Appuyer sur valider pour enregistrer'}})
    }
  }

  const onUploadStart = (response: any) => {
    setUploading(true);
  };

  const onUploading = (response: any) => {
    // console.log('--- START');
    // console.log(response);
  };

  const onUploadDone = async (response: any) => {
    const err:any = {...error};
    if(0 < err.pressCard.length){
      err.pressCard.splice(err.length - 1, 1);
      err.pressCard = err.pressCard.filter((pc: string) => !!pc);
    }

    setError(err);

    const d:any = {...registerData};
    d.pressCard.push(response);

    d.valid = ('' === error.email && '' === error.lastname && '' === error.firstname && '' === error.employerNames 
    && 0 === error.pressCard.length  && !!d.email && !!d.lastname && !!d.firstname && !!d.employerNames && !!d.rulesAgreement && 1 < d.pressCard.length);
    setUploading(false);
    setRegisterData(d);
  }

  const onError = (response: any) => {
    const err:any = {...error};
    err.pressCard.push(response.error);
    setError(err)
    setUploading(false);
  };

  const registration = (e: any) => {
    e.preventDefault();
    // console.log("SEND INSCRIPTION");
    // console.log(registerData);
    if (registerData.valid) {
      setErrorMessage('');
      setError({
        email: '',
        lastname: '',
        firstname: '',
        employerNames: '',
        rulesAgreement: '',
        pressCard: [],
      });
      // console.log("SEND INSCRIPTION Step1 " + registerData);
      Register(registerData)
        .then((response: any) => {
          // console.log("SEND INSCRIPTION Step1 response ");
          // console.log(response);

          if ('ok' === response.status) {
            setConfirmation(true);
            setRegisterData({
              email: '',
              lastname: '',
              firstname: '',
              employerNames: [],
              rulesAgreement: '',
              pressCard: [],
              valid: false
            });

            setTimeout(() => {
              setConfirmation(false);
            }, 5000);
          }else if('string' === typeof response.message){
            setErrorMessage(response.message)
          }else if('object' === typeof response.message && !!response.message.code && 'ENOENT' === response.message.code){
            Object.entries(registerData.pressCard).forEach((p, pi) =>
              removePressCard(pi)
            );
            setErrorMessage('Une erreur est survenue. Veuillez réessayer ultérieurement');
          }else if('object' === typeof response.message){
            const err:any = {...error};
            for(let ind in response.message){
              err[ind] = response.message[ind];
            }
            setError(err);
          }else{
            setErrorMessage('Une erreur est survenue. Veuillez réessayer ultérieurement');
          }
        }, (error: any) => {
          console.log(error)
        });
    }
  }

  const removePressCard = (index: number) => {
    const err:any = {...error};
    err.pressCard[index] = '';
    
    const d:any = {...registerData}
    d.pressCard.splice(index, 1);
    d.valid = (1 < d.pressCard.length);


    setError(err);
    setRegisterData(d);
  }

  useEffect(() => {
    if (isLoggedIn()) {
      navigate('/utilisateur/mon-compte');
    }
  }, []);

  // console.log(confirmation);
  // console.log(registerData);

  return (
    <>
      <Seo title="Inscription | Vu des Quartiers" />
      <div className="page page-grid page-private page-register" data-theme="quaternary-25">
        <div className="page_inner">


          <section className="section section-main section-page section-header section-register">
            <div className="section_inner radius radius-xl radius-right" data-theme="primary">
              <div className="section-title">
                <h1><b>Formulaire d'inscription</b></h1>
              </div>
              <CitySkyline />
              <div className="section-content">

                <div className="section-intro">
                  <p>
                    <strong>L'annuaire n'est accessible qu'aux <span>journalistes détenteurs d'une carte de presse.</span> Tous les champs sont obligatoires.</strong>
                  </p>
                </div>


                <form className="form form-register" noValidate onSubmit={registration} autoComplete="off">
                  <div className="form_inner">
                    <div className="form-row row row-block">
                      <label><span>Votre prénom</span></label>
                      <input type="text" name="firstname" value={registerData.firstname} pattern="" placeholder="ex: Jean" autoComplete="off" onChange={(e) => onChangeData('firstname', e.currentTarget.value)} />
                      {!!error.firstname &&
                        <div className="form-error">
                          {error.firstname}
                        </div>
                      }
                    </div>
                    <div className="form-row row row-block">
                      <label><span>Votre nom</span></label>
                      <input type="text" name="lastname" value={registerData.lastname} pattern="" placeholder="ex: de La Fontaine" autoComplete="off" onChange={(e) => onChangeData('lastname', e.currentTarget.value)} />
                      {!!error.firstname &&
                        <div className="form-error">
                          {error.lastname}
                        </div>
                      }
                    </div>
                    <div className="form-row row row-block">
                      <label><span>Votre email</span></label>
                      <input type="text" name="email" value={registerData.email} pattern="" placeholder="Email" autoComplete="off" onChange={(e) => onChangeData('email', e.currentTarget.value)} />
                      {!!error.email &&
                        <div className="form-error">
                          {error.email}
                        </div>
                      }
                    </div>
                    <div className="form-row row row-block row-input-tags" data-tags={registerData.employerNames.length}>
                      <label>
                        <span>Nom de vos employeurs séparés par des virgules<br />
                          <small>(si vous n'en avez qu'un, enregistrez son nom en validant avec la touche "Enter" de votre clavier)</small>
                        </span>
                      </label>
                      <ReactTags
                        id='employerNames'
                        ref={refTags}
                        tags={registerData.employerNames}
                        placeholderText='ex : Le Monde, Ouest France'
                        noSuggestionsText=''
                        removeButtonText=''
                        allowNew={true}
                        autoresize={true}
                        delimiters={['Enter', 'Tab', 'Comma', ',']}
                        onAddition={(tag: any) => onChangeData('employerNames', tag, false)}
                        onDelete={(tag: number) => onChangeData('employerNames', tag, true)}
                        onBlur={onHandleInputBlur}
                      // classNames={
                      //   root: 'react-tags',
                      //   rootFocused: 'is-focused',
                      //   selected: 'react-tags__selected',
                      //   selectedTag: 'react-tags__selected-tag',
                      //   selectedTagName: 'react-tags__selected-tag-name',
                      //   search: 'react-tags__search',
                      //   searchWrapper: 'react-tags__search-wrapper',
                      //   searchInput: 'react-tags__search-input',
                      //   suggestions: 'react-tags__suggestions',
                      //   suggestionActive: 'is-active',
                      //   suggestionDisabled: 'is-disabled',
                      //   suggestionPrefix: 'react-tags__suggestion-prefix'
                      // }
                      />
                      {/* <input type="text" name="employerNames" value={registerData.employerNames} pattern="" placeholder="ex: Le Monde, Libération, Radio France" onChange={(e) => onChangeData('employerNames', e.currentTarget.value)} /> */}
                      {!!error.employerNames &&
                        <div className="form-error">
                          {error.employerNames}
                        </div>
                      }
                    </div>

                    <div className="form-row row row-block row-upload row-upload-full">
                      <label><span>Votre carte de presse (recto et verso)<br /> dans l'un des formats suivants : pdf / jpg / png</span></label>
                      <UploadChunk
                        btnLabel={`Télécharger vos deux fichiers`}
                        btnIcon={`fas fa-upload`}
                        btnClass={`btn btn-upload btn-cta btn-secondary rounded`}
                        url={`${process.env.GATSBY_API_URL}/api/user/upload`}
                        multiple={true}
                        maxUploadFile={2}
                        mimeTypes={['application/pdf', 'image/jpeg', 'image/jpg', 'image/png']}
                        maxSize={5096000}
                        uploadedFiles={registerData.pressCard}
                        onStart={onUploadStart}
                        onUploading={onUploading}
                        onSuccess={onUploadDone}
                        onError={onError}
                        options={{
                          error: {
                            display: false
                          }
                        }}
                      />
                      {0 < registerData.pressCard.length &&
                        <div className="form-message">
                          <ul className="list">
                            {registerData.pressCard.map((p: any, pi: number) => (
                              <li key={p.id} className="item">
                                <span className="item_icon" onClick={() => removePressCard(pi)}>
                                  <i className="fas fa-times"></i>
                                </span>
                                <span><b>{p.name}</b><small>importé</small></span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      }
                      {!!error.pressCard &&
                        <div className="form-error">
                          {error.pressCard}
                        </div>
                      }
                    </div>
                    <div className="form-row row row-block row-checkbox">
                      <div className="input">
                        <input id="acceptRules" type="checkbox" name="rules_agreement" value="1" onChange={(e) => onChangeData('rulesAgreement', e.currentTarget)} />
                        <label className="checkbox_like" htmlFor="acceptRules"></label>
                        {/* <label className="checkbox_label" htmlFor="acceptRules"><span>J'accepte&nbsp;<AniLink className="link link-primary"
                          fade
                          to="/charte-d-utilisation"
                          target="_blank"
                          entry={{
                            delay: 0.5
                          }}
                        ><span className="link_label">la charte d'utilisation du site</span></AniLink></span></label> */}
                        <label className="checkbox_label" htmlFor="acceptRules"><span>J'accepte&nbsp;<a className="link link-primary"
                          href="/charte-d-utilisation"
                          target="_blank"
                        ><span className="link_label">la charte d'utilisation du site</span></a></span></label>
                      </div>
                      {!!error.rulesAgreement &&
                        <div className="form-error">
                          {error.rulesAgreement}
                        </div>
                      }
                    </div>
                    <div className="form-row row row-block row-submit">
                      <button className={`btn btn-cta btn-secondary invert rounded ${!!confirmation ? 'state-ok' : ''}`}
                        disabled={!registerData.valid}>
                        <span className="btn_label">Soumettre mes informations</span>
                        <span className="btn_icon">
                          <i className="fas fa-arrow-right"></i>
                        </span>
                      </button>
                      {!!errorMessage &&
                        <div className="form-error">
                          {errorMessage}
                        </div>
                      }
                    </div>
                    {!!confirmation &&
                      <div className="bubble bubble-confirmation">
                        <div className="bubble_inner">
                          <div className="bubble__title">
                            Merci pour<br />
                              votre inscription :)
                          </div>
                          <div className="bubble__content">
                            <p>
                              L'équipe de Vu des Quartiers va étudier votre inscription et revient vers vous rapidement.
                            </p>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </form>

                <BlockInfo
                  title={`Information RGPD`}
                  titleIcon={`fas fa-info`}
                  content={`
                  <p>
                  L'association Vue Des Quartiers traite les données recueillies pour gérer la liste des contacts proposée et des journalistes accrédités.<br>
                  Pour en savoir plus sur la gestion de vos données personnelles et pour exercer vos droits, <a href="/charte-d-utilisation" target="_blank" class="link link-inherit"><span class="link_label">reportez-vous à la notice</span></a>.
                  </p>
                  `}
                />
              </div>
            </div>
          </section>
          <Split
            mode={`fluid`}
            left={<Connection setLpStep={setLpStep} onRegisterPage={true} classElt={`radius radius-small radius-left`} dataTheme={`white`} />}
            right={<LostPassword step={lpStep} setLpStep={setLpStep} mode="register" />}
          />
          {/* <Connection setLpStep={setLpStep} onRegisterPage={true} classElt={`radius radius-small radius-bottom radius-left`} dataTheme={`white`} /> */}
        </div>
      </div>
    </>
  );
}

export default RegisterPage;