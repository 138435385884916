import React from "react";

import "./styles.scss";

interface ModalProps {
  modalId?: string;
  title?: string,
  titleIcon?: string,
  content?: any,
  modalButtonLabel?: string,
  modalButtonIcon?: string,
  modalButtonClassName?: string,
  modalOnclick?: any
}


const Modal = ({
  modalId,
  title,
  titleIcon,
  content,
  modalButtonLabel,
  modalButtonIcon,
  modalButtonClassName,
  modalOnclick
}: ModalProps) => {

  return (
    <div id={modalId} data-uk-modal className="modal modal-confirm mini" data-theme="secondary-50">
      <div className="modal_body uk-modal-dialog uk-modal-body radius radius-small radius-bottom" data-theme="primary">
        <div className="modal__inner">
        <button className="btn btn-close btn-no-text" type="button" data-uk-toggle={`target: #${modalId}`}>
            <span className="btn_icon"><i className="fas fa-times"></i></span>
            <span className="btn_label">Fermer</span>
          </button>
          <div className="modal-title">
            <h2>{title}</h2>
          </div>

          <div className="modal-content">
          <div className="modal-content_inner" dangerouslySetInnerHTML={{ __html: content }}></div>
          </div>
          <div className="modal-actions">
            <button type="button" onClick={modalOnclick} className={`${status} ${modalButtonClassName}`}>
            <span className="btn_label">{modalButtonLabel}</span>
            <span className="btn_icon"><i className={modalButtonIcon}></i></span>
            </button>
          </div>
        </div>
      </div>
    </div>

  )
};


export default Modal;