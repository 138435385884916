import React, { useState } from "react"
import { NewPassword } from "../../../../../../services/UserService";
import { clone } from "../../../../../../utils/File";
import { navigate } from "gatsby";

import "./styles.scss";

const NewPasswordModule: React.FunctionComponent<{ action: string, token: string }> = ({ action, token }) => {

  const [isValid, setIsValid] = useState(false);

  const [data, setData] = useState({
    action: action,
    password: '',
    passwordConfirmation: '',
    token: token
  });

  const [errors, setErrors] = useState({
    password: '',
    passwordConfirmation: ''
  });

  const isPasswordValid = (password: string): boolean => {
    const pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    return !!password.match(pattern);
  }

  const onChangeText = (type: string, value: string) => {
    const err = clone(errors);
    const d = clone(data);

    d[type] = value;

    switch (type) {
      case 'password':
        err.password = '';
        if ('' === value) {
          err.password = 'Le mot de passe doit être renseigné';
        } else if (!isPasswordValid(value)) {
          err.password = 'Le mot de passe n\'est pas valide';
        }
        break;
      case 'passwordConfirmation':
        err.passwordConfirmation = '';
        if ('' === value) {
          err.passwordConfirmation = 'La confirmation du mot de passe doit être renseignée';
        } else if (!isPasswordValid(value)) {
          err.passwordConfirmation = 'La confirmation du mot de passe n\'est pas valide';
        } else if (value !== data.password) {
          err.passwordConfirmation = 'La confirmation ne correspond pas au mot de passe';
        }
        break;
    }

    setErrors(err);
    setData(d);
    setIsValid(!!d.password && !!d.passwordConfirmation && '' === err.password && '' === err.passwordConfirmation);
  }

  const sendConfirmation = (e: any) => {
    e.preventDefault();
    NewPassword(data)
      .then(response => {
        if ('ok' === response.status) {
          navigate('/annuaire');
        } else {
          if(response.error.match(/Le token/gi)){
            setErrors({ ...errors, ...{ passwordConfirmation: response.error } })
          }else{
            setErrors({ ...errors, ...{ passwordConfirmation: 'L\'enregistrement de votre mot de passe a échoué' } })
          }
        }
      }, error => {
        setErrors({ ...errors, ...{ passwordConfirmation: 'L\'enregistrement de votre mot de passe a échoué' } })
      })
  }

  // console.log(data);


  return (
    <div className="page page-grid page-cold page-private page-settings page-account page-new-password">
      <div className="page_inner">


        <section className="section section-main section-page section-header mini section-description">
          <div className="section_inner radius radius-small radius-bottom radius-left" data-theme="primary">
            <div className="section-title">
              <h1 className="small"><b>{'reset' === action ? 'Réinitialisation du mot de passe' : 'Validation de votre compte'}</b></h1>
            </div>
          </div>
        </section>
        <section className="section section-secondary section-page">
          <div className="section_inner radius radius-xl radius-right" data-theme="white">
            <div className="section-content">


              <form className="form form-password" onSubmit={sendConfirmation}>
                <div className="form_inner">
                  <div className="form_group">
                    <div className="form_group-title">
                      <h2 className="clr-primary-50">Nouveau mot de passe</h2>
                    </div>
                    <div className="form-row row row-block">
                      <label><span>Mot de passe</span></label>
                      <input type="password" name="password" value={data.password} onChange={(e) => onChangeText('password', e.currentTarget.value)} autoComplete="current-password"/>
                      {!!errors.password &&
                        <div className='error input-error'>{errors.password}</div>
                      }

                    </div>
                    <div className="form-row row row-block">
                      <label><span>Confirmation du mot de passe</span></label>
                      <input type="password" name="password_confirmation" value={data.passwordConfirmation} onChange={(e) => onChangeText('passwordConfirmation', e.currentTarget.value)} autoComplete="current-password"/>
                      {!!errors.passwordConfirmation &&
                        <div className='error input-error'>{errors.passwordConfirmation}</div>
                      }

                    </div>
                    <div className="form-row row row-block row-submit">
                      <button className="btn btn-cta btn-save btn-secondary rounded" disabled={!isValid}>
                        <span className="btn_label">Valider</span>
                        <span className="btn_icon">
                          <i className="fas fa-save"></i>
                        </span>
                      </button>
                    </div>
                  </div>



                  <div className="password-information">
                    Afin de sécuriser votre accès, le mot de passe devra être composé de <span className={`check-password ${data.password.match(/.{8,15}/) ? ' correct' : ' disabled'}`}>8 à 15 caractères</span> et contenir au moins :
                        <ul>
                      <li className={`check-password ${data.password.match(/[a-z]{1}/) ? ' correct' : ' disabled'}`}>
                        <span>Une lettre en minuscule</span>
                      </li>
                      <li className={`check-password ${data.password.match(/[A-Z]{1}/) ? ' correct' : ' disabled'}`}>
                        <span>Une lettre en majuscule</span>
                      </li>
                      <li className={`check-password ${data.password.match(/\d{1}/) ? ' correct' : ' disabled'}`}>
                        <span>Un chiffre</span>
                      </li>
                      <li className={`check-password ${data.password.match(/[^\sa-zA-Z0-9]{1}/) ? ' correct' : ' disabled'}`}>
                        <span>Un caractère spécial</span>
                      </li>
                    </ul>
                  </div>


                </div>
              </form>

            </div>
          </div>
        </section>


      </div>
    </div>
  );
}

export default NewPasswordModule