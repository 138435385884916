import React, { useState } from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import { LostPassword } from "../../../../../../services/UserService";

import './styles.scss';

const LostPasswordModule: React.FC<{ step: string, setLpStep: any, mode: string }> = ({ step, setLpStep, mode }) => {

  const [email, setEmail] = useState('');

  const [error, setError] = useState('');

  const [message, setMessage] = useState('');


  const onChangeEmail = (e: any) => {
    const value = e.currentTarget.value;
    let err = '';
    if ('' === value) {
      err = 'Veuillez renseigner un email';
    } else if (!value.match(/^[\w.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+$/)) {
      err = 'Votre email est invalide';
    }
    setError(err);
    setEmail(value);
  }

  const sendConfirmation = (e: any) => {
    e.preventDefault();
    setError('');
    LostPassword(email)
      .then(response => {
        if ('ok' === response.status) {
          setMessage('Un email avec un lien de réinitialisation de mot de passe vient de vous être envoyé (regardez bien dans vos spams également).<br></br>Si vous ne recevez rien, c’est que vous ne faites pas encore partie de notre base de journalistes.<br></br>Remplissez le <a href="https://staging.vudesquartiers.fr/utilisateur/inscription">formulaire d’inscription</a>.');
          setTimeout(() => {
            setLpStep('register');
            setMessage('');
          }, 10000);
        } else {
          setError(response.error);
        }
      }, error => {
        setError(error);
      })
  }

  return (
    <section className={`section-main section-card section-password`}>
      <div className={`section_inner radius radius-small radius-bottom step step0 ${'empty' === step ? 'step-active' : 'step-inactive'}`} data-theme="white">

      </div>
      <div className={`section_inner radius radius-small radius-bottom step step1 ${'register' === step ? 'step-active' : 'step-inactive'}`} data-theme="white">
        <div className="section-title">
          <h2><b>Je n'ai pas de compte</b></h2>
        </div>
        <div className="section-content">
          <div className="illustration">
            <img src={`/assets/images/svg/quiz.svg`} alt=" " />
          </div>
          <AniLink
            fade
            to="/utilisateur/inscription"
          >
            <button className="btn btn-cta btn-secondary rounded">
              <span className="btn_label">Remplir le formulaire d'inscription</span>
              <span className="btn_icon">
                <i className="fas fa-arrow-right"></i>
              </span>
            </button>
          </AniLink>
        </div>
      </div>
      <div className={`section_inner radius radius-small radius-bottom step step-password ${'lost' === step ? 'step-active' : 'step-inactive'}`} data-theme="primary">
        <div className="section-title">
          <h2><b>Mot de passe perdu</b></h2>
          <button className="btn btn-close btn-no-text" type="button" onClick={() => setLpStep("register" == mode ? "empty" : "register")}>
            <span className="btn_icon"><i className="fas fa-times"></i></span>
            <span className="btn_label">Fermer</span>
          </button>
        </div>
        <div className="section-content">
          <p>
            <b>Un email va vous être envoyé pour réinitialiser votre mot de passe</b>
          </p>
          <form className="form form-lostpassword" onSubmit={sendConfirmation}>
            <div className="form_inner">
              <div className="form-row row row-block">
                <label><span>Inscrivez votre email</span></label>
                <input type="email" name="email" value={email} onChange={onChangeEmail} placeholder='Email' />
                {!!error &&
                  <div className='error input-error'>{error}</div>
                }
              </div>
              <div className="form-row row row-block row-submit">
                <button className="btn btn-cta btn-secondary invert rounded" type="submit" disabled={'' == email}>
                  <span className="btn_label">Envoyer</span>
                  <span className="btn_icon">
                    <i className="fas fa-arrow-right"></i>
                  </span>
                </button>
              </div>

            </div>

          </form>

        </div>
        {!!message &&
          <div className="form-row row row-block row-message row-message-confirmation">
            <div className="message_inner">
              <div className="confirmation-message" dangerouslySetInnerHTML={{ __html: message }}></div>
            </div>
          </div>
        }
      </div>
    </section>
  );
}

export default LostPasswordModule