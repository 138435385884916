import React, { useEffect, useState } from "react"
import Seo from "../../../../../structure/Seo/Seo"
import { navigate } from 'gatsby';
import CitySkyline from "../../../../../element/Svg/CitySkyline";

import "./styles.scss";
import { ChangePassword, DeleteUser } from "../../../../../../services/UserService";
import { clone } from "../../../../../../utils/File";
import { isLoggedIn } from "../../../../../../services/AuthService";
import UIkit from "uikit";


import Modal from "../../../../../element/Modal/Modal";
import { clearLSFilters } from "../../../../../../utils/Data";

const SettingsPage = () => {

  const [isValid, setIsValid] = useState(false);

  const [status, setStatus] = useState('');

  const [deleteUSer, setDeleteUSer] = useState(false);

  const [data, setData] = useState({
    password: '',
    passwordConfirmation: '',
  });

  const [errors, setErrors] = useState({
    password: '',
    passwordConfirmation: ''
  });

  const isPasswordValid = (password: string): boolean => {
    const pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    return !!password.match(pattern);
  }

  const onChangeText = (type: string, value: string) => {
    const err = clone(errors);
    const d = clone(data);

    d[type] = value;

    switch (type) {
      case 'password':
        err.password = '';
        if ('' === value) {
          err.password = 'Le mot de passe doit être renseigné';
        } else if (!isPasswordValid(value)) {
          err.password = 'Le mot de passe n\'est pas valide';
        }
        break;
      case 'passwordConfirmation':
        err.passwordConfirmation = '';
        if ('' === value) {
          err.passwordConfirmation = 'La confirmation du mot de passe doit être renseignée';
        } else if (!isPasswordValid(value)) {
          err.passwordConfirmation = 'La confirmation du mot de passe n\'est pas valide';
        } else if (value !== data.password) {
          err.passwordConfirmation = 'La confirmation ne correspond pas au mot de passe';
        }
        break;
    }

    setErrors(err);
    setData(d);
    setIsValid(!!d.password && !!d.passwordConfirmation && '' === err.password && '' === err.passwordConfirmation);
  }

  const saveSettings = (e: any) => {
    e.preventDefault();
    setStatus('processing');
    ChangePassword(data)
      .then(response => {
        if ('ok' === response.status) {
          setData({
            password: '',
            passwordConfirmation: '',
          });
          setStatus('done');
          setTimeout(() => {
            setStatus('');
          }, 50000);
        }
      }, err => console.log(err.message));
  }

  const deleteAccount = () => {
    DeleteUser()
      .then(response => {
        if ('ok' === response.status) {
          if (!!localStorage && !!localStorage.getItem('userContext')) {
            localStorage.removeItem('userContext');
          }
          setTimeout(() => {
            setDeleteUSer(true);
            setTimeout(() => {
              // @ts-ignore
              UIkit.modal("#modalDelete").hide();
              navigate('/');
            }, 5000);
          }, 500);
        }
      })
  }

  const goToHome = () => {
    // @ts-ignore
    UIkit.modal("#modalDelete").hide();
    navigate('/');
  }

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate('/annuaire');
    }

    clearLSFilters();
  }, []);


  return (
    <>
      <Seo title="Réglages de compte" />
      <div className="page page-grid page-cold page-private page-settings page-account" data-theme="primary-25">
        <div className="page_inner">

          <section className="section section-main section-page section-header mini section-description">
            <div className="section_inner radius radius-small radius-bottom radius-left" data-theme="primary">
              <div className="section-title">
                <h1 className="small"><b>Réglages du compte</b></h1>
              </div>
            </div>
            <CitySkyline />
          </section>


          <section className="section section-secondary section-page">
            <div className="section_inner radius radius-xl radius-right" data-theme="white">

              <div className="section-content">


                <form className="form form-password" onSubmit={saveSettings}>
                  <div className="form_inner">
                    <div className="form_group">
                      <div className="form_group-title">
                        <h2 className="clr-primary-50">Changer de mot de passe</h2>
                      </div>


                      <div className="form-row row row-block">
                        <label><span>Nouveau mot de passe</span></label>
                        <input type="password" name="password" value={data.password} onChange={(e) => onChangeText('password', e.currentTarget.value)} autoComplete="current-password"/>
                        {!!errors.password &&
                          <div className='error input-error'>{errors.password}</div>
                        }

                      </div>
                      <div className="form-row row row-block">
                        <label><span>Répéter le nouveau mot de passe</span></label>
                        <input type="password" name="password_confirmation" value={data.passwordConfirmation} onChange={(e) => onChangeText('passwordConfirmation', e.currentTarget.value)} autoComplete="current-password"/>
                        {!!errors.passwordConfirmation &&
                          <div className='error input-error'>{errors.passwordConfirmation}</div>
                        }

                      </div>
                      <div className="form-row row row-block row-submit">
                        <button className={`btn btn-cta btn-save btn-secondary rounded ${'done' == status ? 'state-ok' : ''}`} disabled={!isValid}>
                          <span className="btn_label">Enregistrer</span>
                          <span className="btn_icon">
                            <i className="fas fa-save"></i>
                          </span>
                        </button>
                      </div>
                      {'done' == status &&
                        <div className="bubble bubble-confirmation">
                          <div className="bubble_inner">
                            {/* <div className="bubble__title"></div> */}
                            <div className="bubble__content">
                              <p>Votre mot de passe a été mis à jour :)</p>
                            </div>
                          </div>
                        </div>
                      }

                      <div className="password-information">
                        Afin de sécuriser votre accès, le mot de passe devra être composé de <span className={`check-password ${data.password.match(/.{8,15}/) ? ' correct' : ' disabled'}`}>8 à 15 caractères</span> et contenir au moins :
                        <ul>
                          <li className={`check-password ${data.password.match(/[a-z]{1}/) ? ' correct' : ' disabled'}`}>
                            <span>Une lettre en minuscule</span>
                          </li>
                          <li className={`check-password ${data.password.match(/[A-Z]{1}/) ? ' correct' : ' disabled'}`}>
                            <span>Une lettre en majuscule</span>
                          </li>
                          <li className={`check-password ${data.password.match(/\d{1}/) ? ' correct' : ' disabled'}`}>
                            <span>Un chiffre</span>
                          </li>
                          <li className={`check-password ${data.password.match(/[^\sa-zA-Z0-9]{1}/) ? ' correct' : ' disabled'}`}>
                            <span>Un caractère spécial</span>
                          </li>
                        </ul>
                      </div>
                    </div>






                    <div className="form_group">
                      <div className="form_group-title">
                        <h2 className="clr-primary-50">Supprimer mon compte</h2>
                      </div>
                      <div className="message message-warning">
                        <span className="message_icon"><i className="fas fa-exclamation-circle"></i></span>
                        <span className="message_label">Attention, cette action est irréversible</span>
                      </div>
                      <button className="btn btn-cta btn-save btn-secondary rounded" type="button" data-uk-toggle="target: #modalDelete">
                        <span className="btn_label">Supprimer</span>
                        <span className="btn_icon">
                          <i className="fas fa-trash"></i>
                        </span>
                      </button>
                    </div>


                  </div>
                </form>


              </div>
            </div>
          </section>

          <Modal
            modalId={`modalDelete`}
            title={`${!!deleteUSer ? 'Compte supprimé' : 'Supprimer mon compte'}`}
            content={`${!!deleteUSer ? 'Nous avons bien pris en compte votre demande de suppression de compte' : '<p>Êtes-vous sûr de vouloir supprimer<br> votre compte ?</p>'}`}
            modalButtonLabel={`${!!deleteUSer ? 'Revenir sur Vudesquartiers.fr' : 'Supprimer mon compte'}`}
            modalButtonIcon={`${!!deleteUSer ? 'fas fa-angle-right' : 'fas fa-trash'}`}
            modalButtonClassName={`btn btn-secondary btn-cta rounded`}
            modalOnclick={!!deleteUSer ? goToHome : deleteAccount}
          />


        </div>
      </div>
    </>
  );
}

export default SettingsPage