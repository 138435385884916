import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from 'axios';

const UploadChunk:React.FC<{
    btnLabel: string, 
    btnIcon: string, 
    btnClass: string, 
    url: string, 
    customHeaders?: any, 
    mimeTypes: any, 
    filename?: string, 
    chunkSize?: number, 
    maxSize?: number, 
    onStart: any, 
    onSuccess: any, 
    onError: any, 
    onUploading: any, 
    options: any,
    multiple: boolean,
    maxUploadFile?: number,
    uploadedFiles?: any[]
}> = ({ btnLabel, btnIcon, btnClass, url, customHeaders, mimeTypes, filename, chunkSize = 1048576, maxSize, onStart, onSuccess, onError, onUploading, options, multiple, maxUploadFile, uploadedFiles }) => {

    const [error, setError] = useState<string>('');

    const dropAreaId = 'dropArea' + new Date().getTime();

    const config = {
        ...{
            error: {
                display: true
            }
        }, ...options
    };

    const [headers, setHeaders] = useState({
        'Content-Type': 'multipart/form-data; charset=utf-8; boundary=--------------' + Math.random().toString().substr(2)
    })

    function fileConvertSize(size: any) {
        size = Math.abs(parseInt(size, 10));
        let def: any = [[1, 'octets'], [1024, 'ko'], [1024 * 1024, 'Mo'], [1024 * 1024 * 1024, 'Go'], [1024 * 1024 * 1024 * 1024, 'To']];
        for (let i = 0; i < def.length; i++) {
            if (size < def[i][0]) return (size / def[i - 1][0]).toFixed(2) + ' ' + def[i - 1][1];
        }
    }

    const handleChangeFile = async (e: any) => {
        if (e.target.files.length) {

            processUploadFile(0, e.target.files);
        }
    }

    const processUploadFile = (index: number, files: FileList) => {
        if(!!files[index]){

            if(!!multiple && !!maxUploadFile && 0 < maxUploadFile && (!!uploadedFiles && (uploadedFiles.length >= maxUploadFile || (uploadedFiles.length + index + 1) > maxUploadFile))){
               return;
            }

            if (-1 === mimeTypes.indexOf(files[index].type)) {
                setError(`Ce type de fichier n\'est pas autorisé (${files[index].name} - ${files[index].type})`);
                onError({ error: `Ce type de fichier n\'est pas autorisé (${files[index].name} - ${files[index].type})` });
            } else if (!!maxSize && maxSize < files[index].size) {
                setError(`La taille du fichier ne doit pas excéder${fileConvertSize(maxSize)} (${files[index].name})`);
                onError({ error: `La taille du fichier ne doit pas excéder ${fileConvertSize(maxSize)} (${files[index].name})`});
            } else {
                setError('');

                upload(files[index])
                    .then(response => {
                        if (!!onSuccess) {
                            onSuccess(response);
                        }
                    });
            }

            if(!!multiple){
                if(!!maxUploadFile && 0 < maxUploadFile){
                    if((index+1) < maxUploadFile && (!!uploadedFiles &&uploadedFiles.length < maxUploadFile )){
                        processUploadFile((index + 1), files);
                    }
                }else if ((index + 1) < files.length){
                    console.log('__PUTAIN')
                    processUploadFile((index + 1), files);
                }
            }
        }
    }

    const upload = async (file: File) => {
        return new Promise((resolve, reject) => {
            const fileSize = file.size;
            let currentChunk: number = 1;
            const totalChunks: number = Math.ceil(fileSize / chunkSize);
            const lastChunkSize: number = (chunkSize < fileSize) ? fileSize - (chunkSize * totalChunks) : fileSize;
            const uniqueId: string = (Math.random().toString(16) + '000000000').substr(2, 14) + new Date().getTime();
            const offset: number = (0 < (currentChunk - 1)) ? (currentChunk - 1) * chunkSize : 0;
            let blob = file.slice(offset, (offset + chunkSize));
            let loadedFileSize: number = 0;

            if (!!onStart) {
                onStart({
                    uniqueId: uniqueId,
                    currentChunk: currentChunk,
                    totalChunks: totalChunks,
                    loaded: loadedFileSize,
                    totalSize: file.size
                });
            }

            const reader:FileReader = new FileReader();
            reader.readAsArrayBuffer(blob);
            reader.onload = (e: any) => {
                let fd = new FormData();
                // console.log(reader);
                fd.append('file', new File([reader.result], uniqueId));
                fd.append('fileType', file.type);
                fd.append('loaded', loadedFileSize.toString());
                fd.append('filename', !!filename ? filename : file.name);
                fd.append('totalChunks', totalChunks.toString());
                fd.append('_uniqueId', uniqueId);
                fd.append('_chunkNumber', currentChunk.toString());
                fd.append('_chunkSize', chunkSize.toString());
                fd.append('_currentChunkSize', (currentChunk !== totalChunks) ? chunkSize.toString() : lastChunkSize.toString());
                fd.append('_totalSize', file.size.toString());

                axios.post(url, fd, {
                    headers,
                    withCredentials: true
                }).then(async (response) => {
                    switch (response.data.status) {
                        case 'continue':
                            currentChunk++;

                            loadedFileSize += chunkSize;
                            if (loadedFileSize < file.size) {
                                blob = file.slice(loadedFileSize, loadedFileSize + chunkSize);
                                reader.readAsArrayBuffer(blob);
                            }

                            if (!!onUploading) {
                                onUploading({
                                    uniqueId: uniqueId,
                                    currentChunk: currentChunk,
                                    totalChunks: totalChunks,
                                    loaded: loadedFileSize,
                                    totalSize: file.size
                                });
                            }
                            break;
                        case 'done':
                            setError('');
                            resolve({
                                id: uniqueId,
                                name: file.name
                            });
                            break;
                        default:
                            if (!!onError) {
                                onError({ error: "L'upload a échoué" });
                            }
                    }
                }, (err) => {
                    // console.log(err);
                    reject(err);
                    setError(err);
                    if (!!onError) {
                        onError({ error: err });
                    }
                });
            }
        });
    }

    useEffect(() => {
        if (!!customHeaders) {
            setHeaders({ ...headers, ...customHeaders });
        }
    }, []);

    return (
        <div data-uk-form-custom id={dropAreaId}>
            <input id="file" disabled={(!!maxUploadFile && (!!uploadedFiles && uploadedFiles.length >= maxUploadFile))} type="file" multiple={!!multiple} accept={mimeTypes.join(', ')} onClick={(e) => {e.currentTarget.value = ''}} onChange={handleChangeFile} />
            <button type="button" className={btnClass} disabled={!!uploadedFiles && uploadedFiles.length == 2}>
                <span className="btn_label">{btnLabel}</span>
                <span className="btn_icon"><i className={btnIcon}></i></span>
            </button>

            {(!!error && !!config.error.display) &&
                <div className="error">
                    <span>{error}</span>
                </div>
            }
        </div>
    )
}

UploadChunk.propTypes = {
    url: PropTypes.string.isRequired,
    multiple: PropTypes.bool,
    maxUploadFile: PropTypes.number,
    uploadedFiles: PropTypes.array,
    mimeTypes: PropTypes.array.isRequired,
    filename: PropTypes.string,
    chunkSize: PropTypes.number,
    maxSize: PropTypes.number,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    onUploading: PropTypes.func,
    options: PropTypes.object,
};

export default UploadChunk;