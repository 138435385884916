import React, { useEffect, useState } from "react";
import { isLoggedIn } from "../../../../../services/AuthService";
import { getParameter } from "../../../../../utils/Url";
import Seo from "../../../../structure/Seo/Seo";
import { navigate } from 'gatsby';

import NewPasswordModule from "../Modules/NewPassword/NewPassword";

const Validation = () => {
  
  const [action, setAction] = useState<string>('');

  const [token, setToken] = useState<string>('');

  useEffect(() => {
    if(isLoggedIn()){
      navigate('/utilisateur/mon-compte');
    }else{
      setToken(getParameter('token').replace(/\s/g, '+'));
      setAction(getParameter('action'));
    }
  }, []);

  return (
    <>
      <Seo title="Nouveau mot de passe" />
      {(!!token && !!action) && 
          <NewPasswordModule
            token={token}
            action={action}
          />
      }
    </>
  );
}

export default Validation;