import axios from "axios";
import getAuthToken from "./AuthService";

interface DataAuthenticate {
    login: string,
    password: string
}

interface DataRegister {
    email: string,
    lastname: string,
    firstname: string,
    employerNames: any,
    rulesAgreement: any,
    pressCard: any
}

interface PasswordResetInterface {
  action: string,
  token: string,
  password: string,
  passwordConfirmation: string
}

interface PasswordUpdateInterface {
    password: string,
    passwordConfirmation: string
  }

const Authenticate = (dataAuth: DataAuthenticate) => {
    return new Promise((resolve, reject) => {
        axios.post(`${process.env.GATSBY_API_URL}/api/user/login`, dataAuth,
        {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        })
        .then(response => {
            resolve(response);
        }, error => {
            reject(error);
        });
    });
}

const Register = (dataReg: DataRegister): any => {
    const data = {...dataReg};
    data.employerNames = data.employerNames.map((e: any) => e.name).join(', ');
    return new Promise((resolve, reject) => {
        axios.post(`${process.env.GATSBY_API_URL}/api/user`, data,
        {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        })
        .then(response => {
            resolve(response.data);
        }, error => {
            reject(error);
        });
    });
}

const CheckToken = (action: string, token: string):Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post(`${process.env.GATSBY_API_URL}/api/user/valid-token/${action}`, {token: token},
        {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        })
        .then(response => {
            resolve(response.data);
        }, error => {
            reject(error);
        });
    });
}

const NewPassword = (data: PasswordResetInterface):Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post(`${process.env.GATSBY_API_URL}/api/user/password-reset`, data,
        {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        })
        .then(response => {
            resolve(response.data);
        }, error => {
            reject(error);
        });
    });
}

const ChangePassword = (data: PasswordUpdateInterface):Promise<any> => {
    return new Promise((resolve, reject) => {
        const token = getAuthToken();
        axios.post(`${process.env.GATSBY_API_URL}/api/user/password-update`, data,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            withCredentials: true
        })
        .then(response => {
            resolve(response.data);
        }, error => {
            reject(error);
        });
    });
}

const DeleteUser = ():Promise<any> => {
    return new Promise((resolve, reject) => {
        const token = getAuthToken();
        axios.delete(`${process.env.GATSBY_API_URL}/api/user`,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            withCredentials: true
        })
        .then(response => {
            resolve(response.data);
        }, error => {
            reject(error);
        });
    });
}

const LostPassword = (email: string):Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post(`${process.env.GATSBY_API_URL}/api/user/request-password-reset`, {email: email},
        {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        })
        .then(response => {
            resolve(response.data);
        }, error => {
            reject(error);
        });
    });
}


export { Authenticate, Register, CheckToken, NewPassword, LostPassword, ChangePassword, DeleteUser }