import React, { useEffect } from "react"
import { Router, Redirect } from "@reach/router"
import Layout from "../../components/structure/Layout/Layout"
import PrivateUserSettingsPage from "../../components/pages/Private/User/Pages/Settings/Settings";
import PrivateAnnuaireRegistration from '../../components/pages/Private/User/Pages/Register/Register';
import PrivateUserValidation from '../../components/pages/Private/User/Pages/Validation';
import { isLoggedIn } from "../../services/AuthService";

const UserPage = () => {
  return (
    <Layout current='mon-compte'>
        <Router basepath="/utilisateur" className="page-wrapper" data-theme={isLoggedIn() ? 'primary-25' : 'quaternary-25'}>
            <Redirect from="/" to={isLoggedIn() ? '/utilisateur/mon-compte' : '/utilisateur/inscription'} noThrow />
            <PrivateUserSettingsPage path="mon-compte" />
            <PrivateAnnuaireRegistration path="inscription" />
            <PrivateUserValidation path="validation" />
        </Router>
    </Layout>
  );
}

export default UserPage